import { firebase } from './firebase';

import 'firebase/database';

// TODO : Make these error variables more dynamic and usable
// TODO : Rewrite database refs and handle data formatting on the back end rather than in the component unless necessary
// TODO : Handle all outcomes for every firebase call
// TODO : Dynamic importing of firebase/database (reduces 275K on bundle size)
const callbackError = new Error('Callback function required to handle value(s)');
const userIdError = new Error('No user id provided for the file(s)');
const gameIdError = new Error('No game id provided for the file(s)');

const getGames = (callback) => {
	if (!callback) throw callbackError;
	firebase.database().ref('games')
		.once('value', (snapshot) => {
			const games = snapshot.val();
			callback(games);
		});
};

const getFiles = (userId, gameId, callback) => {
	if (!callback) throw callbackError;
	if (!userId) throw userIdError;
	if (gameId) {
		firebase.database().ref(`files/${userId}`).child(gameId)
			.once('value', (snapshot) => {
				callback(snapshot.val());
			});
	} else {
		firebase.database().ref('files').child(userId)
			.once('value', (snapshot) => {
				callback(snapshot.val());
			});
	}
};

const getFile = (userId, gameId, fileId, callback) => {
	if (!callback) throw callbackError;
	if (!userId) throw userIdError;
	if (!gameId) throw gameIdError;
	if (!fileId) throw new Error('No file id provided for the hunts');
	const fileRef = firebase.database().ref(`files/${userId}/${gameId}`);
	fileRef.orderByChild('fileId').equalTo(fileId).once('value', (keySnap) => {
		if (!keySnap.exists()) callback(new Error());
		else {
			const fileKey = Object.keys(keySnap.val())[0];
			const huntsRef = firebase.database().ref(`files/${userId}/${gameId}/${fileKey}`);
			huntsRef.once('value', (snapshot) => callback(snapshot.val()));
		}
	});
};

const getNewFileId = (callback) => {
	if (!callback) throw callbackError;
	let id;
	firebase.database().ref('files').child('fileId')
		.once('value', (snapshot) => {
			id = snapshot.val();
			callback(id);
			firebase.database().ref('files').update({ fileId : id + Math.ceil(Math.random() * 3) });
		});
};

const createNewFile = (userId, gameId, file, errorCallback = console.log) => {
	if (!userId) throw userIdError;
	if (!gameId) throw gameIdError;
	firebase.database().ref(`files/${userId}`).child(gameId).push(file, (err) => { if (err) errorCallback(err); });
};

const updateFileInfo = (userId, gameId, fileId, values, errorCallback = console.log) => {
	if (!userId) throw userIdError;
	if (!gameId) throw gameIdError;
	if (!fileId) throw new Error('No file id provided for updating');
	const ref = firebase.database().ref(`files/${userId}/${gameId}`);
	ref.orderByChild('fileId').equalTo(fileId).once('value', (snapshot) => {
		const fileKey = Object.keys(snapshot.val())[0];
		ref.child(fileKey).update(values, (err) => {
			if (err) errorCallback(err);
		});
	});
};

const deleteFile = (userId, gameId, fileId, callback) => {
	if (!userId) throw userIdError;
	if (!gameId) throw gameIdError;
	if (!fileId) throw new Error('No file id provided for updating');
	const ref = firebase.database().ref(`files/${userId}/${gameId}`);
	ref.orderByChild('fileId').equalTo(fileId).once('value', (snapshot) => {
		const fileKey = Object.keys(snapshot.val())[0];
		ref.child(fileKey).remove((err) => {
			if (err) throw new Error(err);
			if (callback) ref.once('value', (res) => callback(res.val()));
		});
	});
};

const getHunts = (userId, gameId, fileId, callback) => {
	if (!callback) throw callbackError;
	if (!userId) throw userIdError;
	if (!gameId) throw gameIdError;
	if (!fileId) throw new Error('No file id provided for the hunts');
	const fileRef = firebase.database().ref(`files/${userId}/${gameId}`);
	fileRef.orderByChild('fileId').equalTo(fileId).once('value', (keySnap) => {
		if (!keySnap.exists()) callback(new Error());
		else {
			const fileKey = Object.keys(keySnap.val())[0];
			const huntsRef = firebase.database().ref(`files/${userId}/${gameId}/${fileKey}`);
			huntsRef.child('hunts').once('value', (snapshot) => callback(snapshot.val()));
		}
	});
};

const getNewHuntId = (callback) => {
	if (!callback) throw callbackError;
	let id;
	firebase.database().ref('files').child('huntId')
		.once('value', (snapshot) => {
			id = snapshot.val();
			callback(id);
			firebase.database().ref('files').update({ huntId : id + Math.ceil(Math.random() * 3) });
		});
};

const createNewHunt = (userId, gameId, fileId, hunt, errorCallback = console.log) => {
	if (!userId) throw userIdError;
	if (!gameId) throw gameIdError;
	if (!fileId) throw new Error('No file id provided for updating');
	const fileRef = firebase.database().ref(`files/${userId}/${gameId}`);
	fileRef.orderByChild('fileId').equalTo(fileId).once('value', (snapshot) => {
		const fileKey = Object.keys(snapshot.val())[0];
		const huntsRef = firebase.database().ref(`files/${userId}/${gameId}/${fileKey}`);
		huntsRef.child('hunts').push(hunt, (err) => {
			if (err) errorCallback(err);
		});
	});
};

const updateHuntInfo = (userId, gameId, fileId, huntId, values, callback) => {
	if (!userId) throw userIdError;
	if (!gameId) throw gameIdError;
	if (!fileId) throw new Error('No file id provided for updating');
	if (!huntId) throw new Error('No hunt id provided for updating');
	const fileRef = firebase.database().ref(`files/${userId}/${gameId}`);
	fileRef.orderByChild('fileId').equalTo(fileId).once('value', (fileSnap) => {
		const fileKey = Object.keys(fileSnap.val())[0];
		const huntsRef = firebase.database().ref(`files/${userId}/${gameId}/${fileKey}/hunts`);
		huntsRef.orderByChild('huntId').equalTo(huntId).once('value', (huntSnap) => {
			const huntKey = Object.keys(huntSnap.val())[0];
			huntsRef.child(huntKey).update(values, (err) => {
				if (err) throw new Error(err);
				if (callback) callback();
			});
		});
	});
};

const deleteHunt = (userId, gameId, fileId, huntId, callback) => {
	if (!userId) throw userIdError;
	if (!gameId) throw gameIdError;
	if (!fileId) throw new Error('No file id provided for updating');
	if (!huntId) throw new Error('No hunt id provided for updating');
	const fileRef = firebase.database().ref(`files/${userId}/${gameId}`);
	fileRef.orderByChild('fileId').equalTo(fileId).once('value', (fileSnap) => {
		const fileKey = Object.keys(fileSnap.val())[0];
		const huntsRef = firebase.database().ref(`files/${userId}/${gameId}/${fileKey}/hunts`);
		huntsRef.orderByChild('huntId').equalTo(huntId).once('value', (huntSnap) => {
			const huntKey = Object.keys(huntSnap.val())[0];
			huntsRef.child(huntKey).remove((err) => {
				if (err) throw new Error(err);
				if (callback) huntsRef.once('value', (res) => callback(res.val()));
			});
		});
	});
};

const getHuntStatusCodes = (callback) => {
	if (!callback) throw callbackError;
	firebase.database().ref('huntStatusCodes').once('value', (snapshot) => callback(snapshot.val()));
};

const getHuntMethods = (callback) => {
	if (!callback) throw callbackError;
	firebase.database().ref('huntMethods').once('value', (snapshot) => callback(snapshot.val()));
};

const getHuntMethod = (methodId, callback) => {
	if (!callback) throw callbackError;
	firebase.database().ref('huntMethods').child(methodId).once('value', (snapshot) => callback(snapshot.val()));
};

export {
	getGames,
	getFiles,
	getFile,
	getNewFileId,
	createNewFile,
	updateFileInfo,
	deleteFile,
	getHunts,
	getNewHuntId,
	createNewHunt,
	updateHuntInfo,
	deleteHunt,
	getHuntStatusCodes,
	getHuntMethods,
	getHuntMethod,
};
